.App {
  .header {
    background-color: black;
    color: white;
    padding: 1rem;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ececec;
    .menu {
      width: 20%;
      background: white;
    }
    .table-filter {
      width: 100%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
    }
  }
}
